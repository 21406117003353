jQuery.noConflict();
;(function($) {

// SET UP EVERYTHING FOR USER INTERACTIONS
  $(function() {

// global vars
    var
      medium_bp = 896,             // the menu switches main layout here
      mobile_bp = 640,             // the most important breakpoint - defines main transistion from mobile to desktop
      isMobile,                    // tracker variable set by rubberband
      isOldIE   = $('html.oldie'), // in case we need it for scripts - used for iframeResizer
      $window   = $(window),
      $body     = $('body'),
      $assets   = $('div.promoted-assets');

// ------------------------------------------------------------------------------------
// BEGIN: Menu interactions

    // show full menu (hoverIntent)
    $('.c-topnav-itemwrapper').hoverIntent({
      over: function() { $(this).addClass('focus'); },
      out: function() { $(this).removeClass('focus'); },
      sensitivity: 20,
      timeout: 200
    });

    // show mobile menu
    $('.c-mobile-menu-icon').on('click', function() {
      $('.c-mobile-menu').toggleClass('show-this');
    });

    // set active on selected top-level link
    // example: clicking on space NEXT TO THE WORD returns:
    // target is span.c-topnav-top-link.c-topnav-link
    //   ( submenu: span.c-topnav-dropdown-item.c-topnav-dropdown-label )
    // nav > children is the test
    // active needs to be on target parent li
    $('.c-topnav-itemwrapper').on('click', function(e) {
      var target = $(e.target),
          targets_li = target.closest('li');

      // is the target a span or svg? it's always either one of these.
      if ( target.is('span') || target.parent('svg') ) {

        // the target's parent li has a nav that has children
        if ( targets_li.find('nav').children().length > 0) {

          // toggle the target's parent li's active state
          targets_li.toggleClass('active');
        }
      }
    });

    // set active on submenu
    $('.c-topnav-dropdown-label').on('click', function(e) {
      $(this).parent('.c-topnav-level1-itemwrapper').toggleClass('active');
    });

// END Menu interactions

// ------------------------------------------------------------------------------------
// BEGIN: Prevent clicks on these links for menu interactions

  $('.btn-cloud-login').on('click', function(e) {
    e.preventDefault();
  });

// END Prevent clicks

// ------------------------------------------------------------------------------------
// BEGIN: Search interaction

    // select ONLY the search in the header
    $('#edit-search-block-form--2').focus(function() {
      $(this).addClass('active');
    }).blur(function() {
      $(this).removeClass('active');
    });

// END Search interaction

// ------------------------------------------------------------------------------------
// BEGIN: Promo Assets
// on hover move to center

// TODO: promo assets should be optimized to include focus events for accessiblity!
// mouse events are NOT accessible

    $('a.promoted-asset').on({
      mouseenter: function() {
        // going directly from one a to another doesn't trigger mouseout
        // so first hide any already revealed items
        $('a.promoted-asset.reveal').removeClass('reveal');

        // get parent height
        var promo_height = $assets.outerHeight();

        // set parent height to prevent flickering when a changes size
        $assets.css({ 'height' : promo_height + 'px' });

        // force height for individual promo asset div
        var promo_div = $assets.find('.promo-assets-block');
        var promo_div_height = promo_div.outerHeight();

        // set asset div height to prevent flickering when a changes size
        promo_div.css({ 'height' : promo_div_height + 'px' });

        // reveal the hidden text
        $(this).addClass('reveal');

        // get some heights and do some maths
        var my_height = $(this).outerHeight(),
            siblings = $(this).parent().siblings(),
            others_height = siblings.outerHeight(),
            difference = Math.floor( (my_height - others_height) / 2 ),
            single_difference = Math.floor( (my_height - promo_height)  / 2 );

        // is there a sibling?
        // if single, use parent container height
        // if there's multiple, use sibling height - needs this for stacked layout
        if ( !(siblings.length) ) {
          $(this).css({
            'margin-top' : '-' + single_difference + 'px',
            'margin-bottom' : '-' + single_difference + 'px'
          });
        } else {
          $(this).css({
            'margin-top' : '-' + difference + 'px',
            'margin-bottom' : '-' + difference + 'px'
          });
        }
      },
      mouseleave: function() {
        // reset everything, remove class
        $assets.css({ 'height' : 'auto' });
        var promo_div = $assets.find('.promo-assets-block');
        promo_div.css({ 'height' : 'none' });
        $(this).css({
          'margin-top' : 'auto',
          'margin-bottom' : 'auto'
        }).removeClass('reveal');
      }
    });

// END Promo Assets

// ------------------------------------------------------------------------------------
// BEGIN: dropdown link list

// TODO: is this needed? there are no translations.  possibly on artwork?

    // on click, show translations
    $('[data-dropdown]').on('click', '.button', function(e) {
      e.preventDefault();
      var $link_group = $(this).parent().siblings('.list-dropdown');
      if ( $link_group.hasClass('open') ) {
        $link_group.removeClass('open'); // am I open? close me and stop.
      } else {
        $('.list-dropdown').removeClass('open'); // is someone else open? close them,
        $link_group.addClass('open'); // and open me.
      }
    });

// END dropdown link list

// ------------------------------------------------------------------------------------
// BEGIN: Accordion Tab Functionality
// (when in accordion mode only!)

    $('input[name*="additional-group"]').on('click', function() {
      // check to see if this is an accordion layout or tab
      if ( $(this).siblings('label').width() > 290 ) {
        // scrolls to top of browser on click
        var this_top = $(this).position();
        $('html,body').animate({scrollTop: this_top.top},'slow');
      }
    });

// End Accordion Tab Functionality

// ------------------------------------------------------------------------------------
// BEGIN: Read More & Close

    // Expected HTML:
    // <div class="content">
    //   <div class="title-area">
    //     <h3>Title</h3>
    //   </div>
    //   <p>Some intro text.</p>
    //   <p class="l_read-more-open read-more">Read more</p>
    //   <div class="c_read-more-copy">
    //     <p>Some more text (probably a lot).</p>
    //     <p class="l_read-more-close read-more">Close</p>
    //   </div>
    // </div>

    // hide extra content on load
    $('.c_read-more-copy').each(function () {
      $(this).hide();
    });
    // open function
    // show this extra content and hide "read more" link
    $('.l_read-more-open').on('click', function() {
      $(this).hide().siblings('.c_read-more-copy').show();
    });
    // close function
    // hide this extra content and show "read more" link
    $('.l_read-more-close').on('click', function() {
      console.log($(this).parent().html());
      $(this).parent('.c_read-more-copy').hide().siblings('.l_read-more-open').show();
    });

// END Read More & Close

// ------------------------------------------------------------------------------------
// BEGIN: File download tracker - see admin/info

  $('.new_file_item a, .tablet_buttons a').click(function(){
    $.ajax({
      type: 'POST',
      url: '/sites/all/ajax/track_user_links.php',
      data: { file: $(this).text() },
      dataType: JSON,
      async: true
    });
    if (_gaq) { _gaq.push(["_trackEvent", "Outbound links", "Click", this.href]); }
  });

// END File download tracker

// ------------------------------------------------------------------------------------
// BEGIN: opens sf/connect links in a new window

  // This link structure is being used on the user action buttons ~sker 12/15~
  $('a[href*="sf/connect"]').each(function(){
    $(this).attr("target", "_blank");
  });

// END open sf/connect links

// ------------------------------------------------------------------------------------
// BEGIN: open pdf files in a new window

  $("a[target!='_blank'][href$='.pdf']").each(function(){
    $(this).attr("target", "_blank");
  });

// END pdf in new window

  // END Dom loaded code
  });
})(jQuery);